import React from 'react';
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';

const Potenciometros = () => {
  return (
    <div>
      <HeaderInicio />
      <div>
        <h1 className="tituloB">Potenciometro</h1>
      </div>
      <FooterInicio />
    </div>
  );
};

export default Potenciometros;
