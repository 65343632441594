import React from 'react';
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';

const Protoboard = () => {
  return (
    <div>
      <HeaderInicio />
      <div>
        <h1 className="tituloB">Protoboard</h1>
      </div>
      <FooterInicio />
    </div>
  );
};

export default Protoboard;
