import { EncabezadoAdmin } from './ComponenetesAdmin/Encabezado';

function AdminPagina() {
  return (
    <div>
      <EncabezadoAdmin />
      <div>
        <h1>Administrar pagina web</h1>
      </div>
    </div>
  );
}

export default AdminPagina;
